.slogan {
    background-color: var(--color-blue-second);
    padding-top: 48px;

    &__text {
        font-size: 36px;
        line-height: 140%;
        color: var(--color-black);

        @media screen and (max-width: 767px) {
            font-size: 24px;
        }

        &-top {
            margin-bottom: 8px;
            text-transform: uppercase;
            text-align: center;
        }

        &-bottom {
            text-align: center;
        }
    }
}
