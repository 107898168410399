@import '../../components/link-icon/link-icon';
@import '../../components/structure/Grid/grid';
@import '../../components/structure/Header/header';
@import '../../components/structure/Navigation/navigation';
@import '../../components/structure/Main/main';
@import '../../components/structure/Wrapper/wrapper';
@import '../../components/structure/Menu/menu';
@import '../../components/structure/Brands/brands';
@import '../../components/structure/Copmany/company';
@import '../../components/structure/Contacts/contacts';
@import '../../components/structure/Slogan/slogan';
@import '../../components/structure/Footer/footer';
@import '../../components/images/Icon/icon';
@import '../../components/links/link/link';
@import '../../components/typography/Title/Title';
@import '../../components/other/SearchBlock/searchBlock';
@import '../../components/other/List/list';

.main {
    flex-grow: 1;
}
